const layerStyle = {
  id: "route",
  type: "line",
  paint: {
    "line-width": 6,
    "line-color": "#05651b",
  },
};

export default layerStyle;
